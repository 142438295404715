import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.snackBar.dismiss(); // Close existing snackbars
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  showError(message: string): void {
    this.snackBar.dismiss(); // Close existing snackbars
    const snackBarRef = this.snackBar.open(message, 'Dismiss', {
      duration: 3000, // No auto-close
      panelClass: ['error-snackbar'],
    });

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss(); // Close when action is triggered
    });
  }
}
