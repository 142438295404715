import { Component, OnInit, Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ORMLogedInPatient } from 'src/app/models/ormloged-in-patient.model';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationCredentials } from 'src/app/models/authentication-credentials.model';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { IpAddressService } from 'src/app/services/ip-address.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;
  showDOB = false;      // Flag to show DOB field
  showOTP = false;      // Flag to show OTP field
  hidebtn = false; // Flag to hide 
  userId: number = 0;
  isPhoneInput: boolean = true;
  isPatientId: boolean = false;
  dobFormatError: boolean = false;
  dobFormatMessage = "DOB is required in MMDDYYYY format."
  dobRequiredMessage = "DOB is required"
  otpStatus
  activeButton: string = ''; // Variable to track active button
  unauthorizedError: boolean = false;
  credentialsRequiredError: boolean = false;
  isrememberUsername;
  isrememberPassword;
  userDetail;
  rxSubscription: Subscription = new Subscription();
  phoneEntered = false; // Flag to indicate if the phone number is entered 
  alertmessage: boolean = false;
  infomessage
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,
    private logedInUser: ORMLogedInPatient,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private authGuard: AuthGuard,
    private encryptDecryptService: EncryptDecryptService,
    protected ipservice: IpAddressService,
    protected NotificationService: NotificationService
  ) {
    debugger;
    this.authService.isAuthenticated = false;
    this.infomessage = "Please enter a valid phone number.";

  }
  ngOnInit(): void {
    //eslint-disable-next-line
    debugger;
    this.queryParamSubscriber();
    this.buildForm(); // Call the function to initialize the form
    this.getRememberDetail();

  }
  queryParamSubscriber() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.isPatientId = id !== undefined;
      this.isPhoneInput = !this.isPatientId;
      this.userId = id || 0;
    });
  }
  buildForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        { value: this.userId !== 0 ? this.userId : '', disabled: this.isPatientId },
        [Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]   // Validate the formatted phone number
      ],
      password: [''],
      otp: [''],
    });

    this.updateUsernameFieldValidator();
  }

  updateUsernameFieldValidator() {
    if (this.isPatientId) {
      this.loginForm.get('username')?.clearValidators();
      this.loginForm.get('username')?.setValue(this.userId)

    } else {
      // Ensure that we only validate the formatted phone number
      this.loginForm.get('username')?.setValidators([Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]);
    }
    this.loginForm.get('username')?.updateValueAndValidity();
  }

  // Check if the phone number is entered
  onPhoneInput(event: any) {
    let input = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    if (input.length > 10) {
      input = input.slice(0, 10); // Limit the input to 10 digits
    }

    // Apply the phone number format as (123) 456-7891
    if (input.length > 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    } else if (input.length > 3) {
      input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
    } else if (input.length > 0) {
      input = `(${input.slice(0, 3)}`;
    }

    event.target.value = input;  // Set the formatted value in the input field
    this.loginForm.controls['username'].setValue(input);  // Update the form control value
    this.phoneEntered = this.loginForm.get('username')?.valid || false;
  }

  onSubmit() {
    debugger

    if (this.loginForm.valid) {
      debugger
      const formData = this.loginForm.value;
      let payLoad: AuthenticationCredentials = new AuthenticationCredentials();
      // payLoad.username = this.userId !== 0 ? this.userId : formData.username.replace(/\D/g, '');
      payLoad.username = this.userId !== 0 ? this.userId : null;
      payLoad.phone = payLoad.username === null ? this.loginForm.get("username")?.value.replace(/\D/g, '') : null

      debugger
      if (this.activeButton === 'dob') {
        let dob = formData.password;
        payLoad.password = this.changeDateFormat(dob)
      } else if (this.activeButton === 'otp') {
        payLoad.password = formData.otp;
      }
      // Call API based on form submission
      const ApiUrl = this.activeButton === 'dob' ? 'auth/generate-patient-portal-token' : 'auth/verify-patient-otp';
      this.loginApiExecute(ApiUrl, payLoad);
      // this.validationHandle()
    }
  }
  loginApiExecute(ApiUrl, payLoad) {
    // Creating an object that adheres to the Auth interface
    debugger
    this.rxSubscription = this.authService.authenticateUser(ApiUrl, payLoad).subscribe({
      next: (encryptedData: string | any) => {
        debugger;
        if (encryptedData.status === 200) {
          const encryptedUserDetails = this.encryptDecryptService.decryptUsingAES256(encryptedData.data);
          localStorage.setItem('u.', encryptedData.data);
          this.ipservice.getIPAddress();
          this.userDetail = JSON.parse(encryptedUserDetails);
          this.logedInUser.userId = Number(this.userDetail.user_id);
          this.logedInUser.practiceId = Number(this.userDetail.practice_id);
          this.logedInUser.userFullName = this.userDetail.user_fullname;
          this.practiceInfo(this.logedInUser.practiceId);
          this.NotificationService.showSuccess("Patient login successfully!..")
        }
        else if (encryptedData.status === 401) {
          // Handle Unauthorized error
          this.unauthorizedError = true;
          this.NotificationService.showError(this.infomessage);
          // Add custom logic for handling unauthorized access, such as redirecting to a login page.
          // For example: this.router.navigate(['/login']);
        } else {
          // Handle other errors
          // Add custom error handling logic for other types of errors.
        }
      },
      error: (error: any) => {
        console.error('Authentication error:', error?.error?.message);
        this.NotificationService.showError('Unauthorized');
      },
      complete: () => {
        // console.log('Authentication complete');
        this.router.navigate(['/patient/invoices']);
        // Add any logic you want to execute when the observable completes.
      }
    });
  }

  changeDateFormat(dob: any) {
    if (dob) {
      const reformattedDOB = this.reformatDob(dob);
      this.dobFormatError = reformattedDOB === 'Invalid DOB format';
      return this.dobFormatError ? '' : reformattedDOB;
    }
    return '';
  }

  practiceInfo(practiceId: number) {
    this.authService._practiceInfo(practiceId).subscribe((encryptedData: string | any) => {
      if (encryptedData.status === 200) {
        localStorage.setItem('p.', encryptedData.data);
        const encryptedPracticeDetail = this.encryptDecryptService.decryptUsingAES256(encryptedData.data);
        const practiceDetails = JSON.parse(encryptedPracticeDetail);
        const userDetails = {
          practiceName: practiceDetails[0]?.practice_name,
          patientName: this.userDetail.user_fullname,
        };
        this.authService.setPracticeAndPatientNames(userDetails);

      }
    });
  }


  reformatDob(dob: any) {
    if (this.validateDobFormat(dob)) {
      const year = dob.slice(4, 8);
      const month = dob.slice(0, 2);
      const day = dob.slice(2, 4);
      const reformattedDob = `${month}-${day}-${year}`;
      return reformattedDob;
    } else {
      return 'Invalid DOB format';
    }
  }

  validateDobFormat(dob: any) {
    const pattern = /^(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{4}$/;
    if (!pattern.test(dob)) {
      return false; // Date format is invalid
    }

    const year = parseInt(dob.slice(4, 8), 10);
    const month = parseInt(dob.slice(0, 2), 10);
    const day = parseInt(dob.slice(2, 4), 10);

    const dobDate = new Date(year, month - 1, day);

    const currentDate = new Date();

    if (dobDate > currentDate) {
      return false; // DOB is in the future
    }

    return true;
  }

  getRememberDetail() {
    debugger;
    const encryptedCredentials = localStorage.getItem("c.");
    if (encryptedCredentials !== null) {
      try {
        const decryptedCredentials = JSON.parse(this.encryptDecryptService.decryptUsingAES256(encryptedCredentials));
        const { userName, password, isRemember } = JSON.parse(decryptedCredentials);
        this.loginForm.get("username")?.setValue(userName);
        this.loginForm.get("password")?.setValue(password);
        this.loginForm.get("remember")?.setValue(isRemember);
        if (!Validators.email(userName)) {
          this.isPhoneInput = false;
          this.isrememberUsername = userName;
          this.isrememberPassword = password;
          debugger
          // Pass user ID as 'id' parameter in queryParams
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: Number(this.isrememberUsername) },
            queryParamsHandling: 'merge',
          });
          this.isPatientId = this.isrememberUsername !== undefined;

          this.isPhoneInput = !this.isPatientId;
          this.userId = Number(this.isrememberUsername) || 0;
          // console.log(userName, password, isRemember);
          return isRemember;
        }
      } catch (error) {
        console.error('Error parsing decrypted data:', error);
        // Handle parsing error as needed
      }


    } else {
      this.forgetRememberedDetails();
      return false;
    }
  }

  saveRememberDetails(username: any, password: any, isRemember: any) {
    debugger
    const credentials = JSON.stringify({
      userName: username,
      password: password,
      isRemember: isRemember,
    });

    localStorage.setItem("c.", this.encryptDecryptService.encryptUsingAES256(credentials));
  }

  forgetRememberedDetails() {
    localStorage.removeItem("c.");
  }

  shouldUseRememberedDetails() {
    return this.getRememberDetail();
  }
  ngOnDestroy() {
    // Unsubscribe when the component is destroyed to prevent memory leaks.
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  showDOBField() {
    this.hidebtn = true
    this.activeButton = 'dob';
    this.showDOB = true;
    this.showOTP = false;
    this.unauthorizedError = false;
    this.loginForm.get('password')?.setValidators([Validators.required]);
    this.loginForm.get('otp')?.clearValidators();
    this.loginForm.get('password')?.updateValueAndValidity();
    this.loginForm.get('otp')?.updateValueAndValidity();
  }

  // Show the OTP field for login via OTP


  showOTPField() {
    debugger
    this.activeButton = 'otp';
    this.showOTP = true;
    this.showDOB = false;
    this.hidebtn = true

    this.unauthorizedError = false;
    this.loginForm.get('otp')?.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
    this.loginForm.get('password')?.clearValidators();
    this.loginForm.get('otp')?.updateValueAndValidity();
    this.loginForm.get('password')?.updateValueAndValidity();
    this.generateOptForLogin();


  }

  generateOptForLogin() {
    // Creating an object that adheres to the Auth interface
    debugger

    let payLoad: any = {
      userId: this.userId !== 0 ? this.userId : null,
    }
    payLoad.userPhone = payLoad.userId === null ? this.loginForm.get("username")?.value.replace(/\D/g, '') : null

    debugger
    this.rxSubscription = this.authService.generateOtp(payLoad).subscribe({
      next: (encryptedData: string | any) => {
        debugger;
        const encryptedUserDetails: any = this.encryptDecryptService.decryptUsingAES256(encryptedData.message);
        console.log('encryptedUSerDetails',encryptedUserDetails);
        if (encryptedUserDetails.status == 404) {
          debugger
          this.alertmessage = true
          this.infomessage = `${encryptedUserDetails}`;
          this.NotificationService.showError(this.infomessage ? this.infomessage : 'Unable To Load this Patient!...');
        }
        else if (encryptedData.status == 200) {
          // const encryptedUserDetails: any = this.encryptDecryptService.decryptUsingAES256(encryptedData);
          debugger
          this.alertmessage = true
          this.infomessage = `${encryptedUserDetails.replace(/^"|"$/g, '')}`;
          this.NotificationService.showSuccess(this.infomessage ? this.infomessage : 'OTP sent to the number!...');

        }
      },
      error: (error: any) => {
        this.isPatientId = false;
        this.showDOB = false;
        this.showOTP = false;
        this.infomessage = error.error.message;
        this.NotificationService.showError(this.infomessage ? this.infomessage : 'Login Failed!...');
      }
    });
  }
  // getRememberDetail() {
  //   debugger;
  //   const savedCredentials = localStorage.getItem("credentials");
  //   if (savedCredentials) {

  //     const { userName, password, isRemember } = JSON.parse(savedCredentials);
  //     // this.isrememberUsername = userName;
  //     // this.isrememberPassword = password;
  //     this.loginForm.get("username")?.setValue(userName);
  //     this.loginForm.get("password")?.setValue(password);
  //     this.loginForm.get("remember")?.setValue(isRemember);
  //     console.log(userName, password, isRemember);
  //     return isRemember;
  //   } else {
  //     this.forgetRememberedDetails();
  //     return false;
  //   }
  // }

  continuebtn() {
    debugger;

    // Check if OTP is supposed to be shown
    if (this.showOTP === true) {
      // Call the verifyOtp function if OTP should be verified
      this.onSubmit();
    } else {
      // Ensure showOTP is set to false and call onSubmit if OTP isn't required
      this.showOTP = false;
      this.onSubmit();
    }
  }

}
