import { EnvironmentConfiguration } from "src/app/models/environment-configuration";

// The list of file replacements can be found in `angular.json`.
export const appConfig : EnvironmentConfiguration = {
  env_name: 'dev',
  production: true,
  apiUrl: 'https://hmspms.azurewebsites.net/pms-api/', //'https://localhost:5001/api',
  stripeUrl:'https://pay.stripe.com/receipts/payment/',
  cacheTimeInMinutes: 30,
  EncryptKey:"8op*&/b.5^q0z~7K",
  EncryptIV: "mTdKweR5=Q5.#Y1&",
};