<div class="container-fluid">
  <div class="row no-gutter">
    <!-- The content half -->
    <div class="col-md-12 bg-light bg-img">
      <div class="login d-flex align-container">
        <!-- Demo content-->
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-xl-5 mx-auto w-100 w-md-75 w-lg-50 p-0 logoimage " style="min-height: 263px; max-width: 524px;"
            > 
              <div class="form-wrapper" style="border-radius: 5px;
              border: 1px solid rgba(90, 46, 126, 0.20);
              background: rgba(255, 254, 254, 0.80);
              box-shadow: 0px 0px 10px 2px rgba(90, 46, 126, 0.20)">
                <p 
                class="pheading text-center fw-semibold text-wrap" 
                style="color: #5a2e7e;">
                  Patient Portal Login
                </p>
                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                  class="form-container"
                >
                  <!-- Phone number input field (when Patient ID is not used) -->
                  <div *ngIf="!isPatientId && (!showDOB && !showOTP)" class="form-group mb-2">
                    <input
                      id="inputUsername"
                      type="text"
                      placeholder="(000) 000-0000"
                      formControlName="username"
                      class="form-control shadow-sm px-4 py-2 hover-border"
                      (input)="onPhoneInput($event)"
                      maxlength="14"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('username')?.invalid &&
                          loginForm.get('username')?.touched,
                      }"
                    />
                    <div
                      *ngIf="
                        (loginForm.get('username')?.invalid &&
                          loginForm.get('username')?.touched) ||
                        this.alertmessage
                      "
                      class="error-text"
                    >
                      {{ this.infomessage }}
                    </div>
                  </div>

                  <!-- Buttons section (Login with DOB or OTP) -->
                  <div
                    *ngIf="phoneEntered || isPatientId"
                    class="text-center"
                  >
                    <div>
                      <p class="coveyMessage" *ngIf="showDOB">Login with DOB</p>
                      <p
                      class="coveyMessage"
                      *ngIf="showOTP && infomessage"
                      [ngClass]="{'fade-in': showOTP, 'fade-out': !showOTP}"
                    >
                      {{ infomessage }}
                    </p>
                    </div>

                    <div class="col" *ngIf="!hidebtn">
                      <div class="col-12 col-md-12 col-lg-12">
                        <button
                          type="button"
                          class="btn optionButtons text-uppercase shadow-sm btn-state"
                          [ngClass]="{
                            'btn-active active': activeButton === 'dob',
                          }"
                          (click)="showDOBField()"
                        >
                          Login With DOB
                        </button>
                      </div>

                      <div
                        class="col-md-12 col-lg-12 text-center orStyle"
                      >
                        <span>OR</span>
                      </div>

                      <div class="col-12 col-md-12 col-lg-12 mb-2 mt-1 mt-sm-0">
                        <button
                          type="button"
                          class="btn optionButtons text-uppercase shadow-sm btn-state"
                          [ngClass]="{
                            'btn-active active': activeButton === 'otp',
                          }"
                          (click)="showOTPField()"
                        >
                          Request SMS Code
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- DOB Field (shown when DOB login option is selected) -->
                  <div *ngIf="showDOB" class="form-group mb-2">
                    <input
                      id="inputPassword"
                      type="password"
                      minlength="8"
                      maxlength="8"
                      placeholder="Enter Date of Birth MMDDYYYY"
                      formControlName="password"
                      class="form-control shadow-sm px-4 py-2"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('password')?.invalid &&
                          loginForm.get('password')?.touched,
                      }"
                    />
                  </div>
                  <!-- OTP Field (shown when OTP login option is selected) -->
                  <div *ngIf="showOTP" class="form-group mb-2">
                    <input
                      id="otp"
                      type="text"
                      placeholder="Enter OTP"
                      formControlName="otp"
                      minlength="6"
                      maxlength="6"
                      class="form-control shadow-sm px-4 py-2 hover-border"
                      [ngClass]="{
                        'error-border':
                          loginForm.get('otp')?.invalid &&
                          loginForm.get('otp')?.touched,
                      }"
                    />
                  </div>

                  <!-- Error message for invalid credentials -->
                  <!-- <span class="error-message"> Login with OTP </span> -->
                  <span class="error-message" *ngIf="unauthorizedError">
                    Credentials are invalid.
                  </span>

                  <a *ngIf="showDOB || showOTP" class="toggleAnchor" (click)="showOTP ? showDOBField() : showOTPField()">Login with {{showOTP ? 'DOB' : 'OTP'}}</a>
                  <!-- <p class="coveyMessage" *ngIf="showOTP">OTP sent to your *******456</p> -->

                  <!-- Continue button (shown when DOB or OTP field is displayed) -->
                  <div *ngIf="showDOB || showOTP" class="text-center">
                    <button
                      type="submit"
                      class="btn optionButtons text-uppercase shadow-sm btn-state"
                      (click)="continuebtn()"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style="position: absolute; right: 5px; bottom: 3px">
          Powered by
          <img
            class="p-0"
            src="../../../assets/images/footer-logo.png"
            alt="Access Statement"
            style="width: 50px"
          />
        </div>

        <!-- End -->
      </div>
    </div>
  </div>
</div>
